// Site Logo SVG

export default function HeaderLogo({ className = "fill-current stroke-transparent h-12 sm:h-16" }) {
  return (
    <svg className={className} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1292 329">
      <g aria-label="Settle">
        <path d="m104.2 278.2c-0.3 0.2-6.2-6.1-10.9-11-20.3-21.1-34-48.1-39-76.6-2.3-12.9-2.4-37.9-0.1-50.5 7.2-41 31.7-77.9 65.7-99.2l9.5-4.4-6.6 5.3c-11.4 12.5-18.9 27.8-20.8 44.3-0.4 3.8-1.9 7.2-6 13.5-10.3 16.3-16.4 34.1-18.4 54.5-2.8 29 5.3 58.9 22.4 82.4l6.3 8.8m144.7 49.8c12.6-9.6 21.8-19.1 27.3-30.6 3.5-7.2 5.1-13.1 8.4-22.7 1-0.6 1.3-5 4.5-10 15.1-23.5 21.7-51 18.9-78.6-2.8-28.6-13.8-52.1-34-73.1l-9.9-10.2 1-13.6c0.6-7.4 1.3-13.8 1.6-14.1 0.7-0.7 12.4 8 19.7 14.6 23 21.1 39.5 51.3 45.1 82.9 2.4 12.9 2.4 37.8 0 50.9-7.7 43.9-34.8 84.3-73.7 102.9-9.7 4.6-11.6 3.6-8.9 1.6z"/>
        <path d="m185.9 309q-20 0-40.4-4-20-4.4-38.4-13.2l5.6-72.9h1.6q4.4 12.5 10.4 24.5 6.4 11.6 14.8 20.8 8.8 9.2 20 15.2 11.6 5.6 26.4 5.6 18 0 29.2-10 11.7-10 11.7-28.4 0-16.8-9.3-28.5-8.8-11.6-22.4-20.8-13.6-9.2-29.2-17.6-15.6-8.4-29.2-19.2-13.6-10.8-22.8-25.2-8.8-14.4-8.8-35.6 0-19.2 6.8-33.6 6.8-14.9 18.8-24.9 12-10 28-15.2 16-5.2 34.4-5.2 20.8 0 39.7 5.6 19.2 5.2 32 11.6l-5.6 70.9h-1.6q-4.4-14-10-25.6-5.6-11.6-13.2-20-7.6-8.9-18.1-13.7-10-4.8-23.2-4.8-15.2 0-25.6 10-10.4 9.7-10.4 24.9 0 14.8 9.2 25.6 9.6 10.4 23.2 19.6 14 8.8 30.4 17.6 16.5 8.4 30.1 20 14 11.2 23.2 26.8 9.6 15.2 9.6 37.7 0 20-8 35.2-7.6 15.2-20.8 25.6-13.2 10.4-30.9 16-17.6 5.2-37.2 5.2z"/>
        <path d="m490.4 294h-155.4v-1.5l21.6-12.4v-169.4l-21.6-12.4v-1.5h149.5v59.2h-1.6q-2.4-7.7-6.3-14.7-3.5-6.9-9.8-12.4-6.3-5.4-15.4-8.7-9-3.3-21.6-3.3h-16.1v65.7h61.7v1.5l-22.8 18.6h-38.9v71.2h22q12.6 0 21.6-4 9.5-4 15.8-10.2 6.2-6.6 10.2-14.3 3.9-8 5.5-15.7h1.6zm192.7-128.5h-1.6q-2.4-9.9-5.5-18.3-2.8-8.7-8.7-15-5.5-6.5-14.1-9.8-8.7-3.7-22-3.7h-7.1v131.9q0 11.3 3.1 19 3.2 7.3 7.9 12 5.1 4.4 11 6.9 5.9 2.6 11.4 4.1v1.4h-123.9v-1.4l33.5-12.5v-161.4h-7.1q-13.4 0-22.1 3.7-8.6 3.3-14.5 9.5-5.5 6.2-8.7 14.9-2.7 8.4-5.1 18.7h-1.5v-68.7h175zm202.5 0h-1.6q-2.3-9.8-5.5-18.2-2.7-8.8-8.6-15-5.5-6.6-14.2-9.9-8.6-3.6-22-3.6h-7.1v131.8q0 11.3 3.2 19 3.1 7.3 7.8 12.1 5.1 4.3 11 6.9 5.9 2.5 11.5 4v1.5h-123.9v-1.5l33.4-12.4v-161.4h-7.1q-13.4 0-22 3.6-8.7 3.3-14.6 9.5-5.5 6.2-8.6 15-2.8 8.4-5.1 18.6h-1.6v-68.6h175zm176.6 128.5h-155v-1.5l21.7-12.4v-169.4l-21.7-12.4v-1.5h112.1v1.5l-33.4 12.4v161.4h23.6q12.6 0 20.8-3.3 8.7-3.3 14.2-9.1 5.9-5.9 9.4-13.2 4-7.6 6.7-15.7h1.6zm177 0h-155.4v-1.4l21.7-12.4v-169.5l-21.7-12.4v-1.5h149.5v59.2h-1.6q-2.3-7.7-6.3-14.6-3.5-7-9.8-12.4-6.3-5.5-15.4-8.8-9-3.3-21.6-3.3h-16.1v65.7h61.7v1.5l-22.8 18.6h-38.9v71.2h22q12.6 0 21.6-4 9.5-4 15.8-10.2 6.3-6.6 10.2-14.2 3.9-8.1 5.5-15.7h1.6z"/>
      </g>
    </svg>
  )
}
